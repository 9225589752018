import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import {
  ChatBox,
  HealthBar,
  MessageInputContainer,
  MessageInputArea,
  Modal,
  SendButton,
  ShareButton,
} from "./Elements.js";

function ChatApp() {
  const [ws, setWs] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [endGameMessage, setEndGameMessage] = useState("");
  const [isStartModalOpen, setStartModalOpen] = useState(true);
  const [isEndModalOpen, setEndModalOpen] = useState(false);
  const [isChatEnabled, setChatEnabled] = useState(false);
  const botHealth = useRef(50);
  const userHealth = useRef(50);
  const gameMode = useRef(null);

  // Initialize WebSocket and handle incoming messages
  useEffect(() => {
    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const websocket = new WebSocket(`${protocol}${window.location.host}/ws`);
    console.log("New Websocket Connection");
    setWs(websocket);

    websocket.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "bot_response") {
        appendMessage(message.message, "bot");
      } else if (
        message.type === "moderator_score" &&
        gameMode.current === "debate"
      ) {
        updateHealthBar(message.score);
      }
      setTimeout(() => {
        setSendButtonDisabled(false);
      }, 1500);
    };

    return () => websocket.close();
  }, []);

  // Append messages to the chatbox
  function appendMessage(text, sender = "user") {
    setMessages((prevMessages) => [...prevMessages, { text, sender }]);
  }

  function handleKeyDown(event) {
    if (event.key === "Enter" && !event.shiftKey && !sendButtonDisabled) {
      event.preventDefault();
      handleSend();
    }
  }

  // Handle sending a message
  function handleSend() {
    if (messageInput) {
      appendMessage(messageInput, "user");
      setSendButtonDisabled(true);
      ws.send(
        JSON.stringify({
          type: "user_input",
          msg: messageInput,
          mode: gameMode.current,
        })
      );
      setMessageInput(""); // Clear input
    }
  }

  // First message for both game modes
  async function firstMessage() {
    const message =
      "Welcome to the Lair of Lomax! What would you like to be proven wrong about today?";
    appendMessage(message, "bot");
    setSendButtonDisabled(false);
  }

  // Start game mode: sandbox or debate
  function startGameMode(mode) {
    gameMode.current = mode;
    setChatEnabled(true);
    setStartModalOpen(false);
    if (mode === "sandbox") {
      firstMessage();
    } else if (mode === "debate") {
      firstMessage();
    }
  }

  // Update health bars based on moderator score
  function updateHealthBar(score) {
    let newBotHealth = botHealth.current;
    let newUserHealth = userHealth.current;

    if (score >= 5) {
      newBotHealth -= score * 3;
      newUserHealth += score * 3;
    } else if (score < 5) {
      newBotHealth += score * 3;
      newUserHealth -= score * 3;
    }

    botHealth.current = Math.max(0, Math.min(newBotHealth, 100));
    userHealth.current = Math.max(0, Math.min(newUserHealth, 100));

    // Check for game over
    if (botHealth.current === 0 || userHealth.current === 0) {
      const winMessage =
        "Lomax concedes, you are truly a master debater! Keanu would be proud :)";
      const loseMessage = "All Hail Lomax! Well, at least you anyways :)";
      setEndGameMessage(newBotHealth === 0 ? winMessage : loseMessage);
      endGame();
    }
  }

  function endGame() {
    setChatEnabled(false);
    setEndModalOpen(true);
  }

  function disableModal() {
    setEndModalOpen(false);
  }

  function EndGameModal() {
    return (
      <div>
        <h2 className="game-over-message">{endGameMessage}</h2>
        <button className="play-again-button" onClick={resetGame}>
          Play Again
        </button>
        <button className="no-thanks-button" onClick={disableModal}>
          No Thanks
        </button>
      </div>
    );
  }

  function StartGameModal() {
    return (
      <div>
        <h2>
          I am Lomax, the Ultimate Devil's Advocate. How would you like to
          debate me today?
        </h2>
        <button
          className="game-mode-button"
          onClick={() => startGameMode("sandbox")}
        >
          Sandbox Mode
        </button>
        <p className="game-mode-description">
          Just you and Lomax, mucking it up for as long as you want, kinda gross
          tbh
        </p>
        <button
          className="game-mode-button"
          onClick={() => startGameMode("debate")}
        >
          Debate Mode
        </button>
        <p className="game-mode-description">
          For you competitive types, time to add an L from Lomax to your trophy
          case
        </p>
      </div>
    );
  }

  function resetGame() {
    userHealth.current = 50;
    botHealth.current = 50;
    setMessages([]);
    setEndModalOpen(false);
    setStartModalOpen(true);
  }

  return (
    <>
      <div id="page-title">
        <h1>Lomax: The Devil's Advocate</h1>
      </div>
      <div id="chat-container">
        <HealthBar
          botHealth={botHealth.current}
          userHealth={userHealth.current}
        />
        <ShareButton />
        <ChatBox messages={messages} />
        <MessageInputContainer
          active={isChatEnabled}
          MessageInputArea={
            <MessageInputArea
              messageInput={messageInput}
              setMessageInput={setMessageInput}
              placeholder="Try if you dare..."
              handleKeyDown={handleKeyDown}
            />
          }
          SendButton={
            <SendButton
              handleSend={handleSend}
              sendButtonDisabled={sendButtonDisabled}
            />
          }
        />
        <Modal show={isStartModalOpen}>
          <StartGameModal />
        </Modal>
        <Modal show={isEndModalOpen}>
          <EndGameModal />
        </Modal>
      </div>
    </>
  );
}

export default ChatApp;
