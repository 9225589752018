import React, { useRef, useEffect } from "react";
import { shareGame } from "./Utils";
import "./App.css";

export function ChatBox({ messages }) {
  const chatboxref = useRef(null);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (chatboxref.current) {
      chatboxref.current.scrollTop = chatboxref.current.scrollHeight; // Scroll to bottom
    }
  }, [messages]);

  return (
    <div ref={chatboxref} className="chatbox">
      {messages.map((msg, index) => (
        <div key={index} className={`message message-${msg.sender}`}>
          <div className="message-icon">
            <img
              src={`/static/${msg.sender}-icon.png`}
              alt={`${msg.sender} Icon`}
            />
          </div>
          <div className="message-bubble">{msg.text}</div>
        </div>
      ))}
    </div>
  );
}

export function HealthBar({ botHealth, userHealth }) {
  return (
    <div className="health-bar-container">
      <div id="health-bar-fill-red" style={{ width: `${botHealth}%` }}></div>
      <div id="health-bar-fill-green" style={{ width: `${userHealth}%` }}></div>
    </div>
  );
}

export function ShareButton() {
  return (
    <div class="share-button-container">
      <button className="share-icon" onClick={shareGame}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          viewBox="0 0 22 22"
        >
          <path d="M12 16v-6h-4l5-5 5 5h-4v6zm8-3v5h-14v-5h-2v5c0 1.104.896 2 2 2h14c1.104 0 2-.896 2-2v-5h-2z" />
        </svg>
      </button>
    </div>
  );
}

export function SendButton({ handleSend, sendButtonDisabled }) {
  return (
    <button id="send-button" onClick={handleSend} disabled={sendButtonDisabled}>
      <svg
        id="send-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zM8.711 4.3l5.7 5.766L8.7 15.711l-1.4-1.422 4.289-4.242-4.3-4.347z" />
      </svg>
    </button>
  );
}

export function MessageInputArea({
  messageInput,
  setMessageInput,
  placeholder,
  handleKeyDown,
}) {
  return (
    <textarea
      id="message-input"
      value={messageInput}
      onChange={(e) => setMessageInput(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
    />
  );
}

export function MessageInputContainer({
  active,
  MessageInputArea,
  SendButton,
}) {
  const isActive = active ? null : "deactivated";
  return (
    <div className={isActive} id="message-input-container">
      {MessageInputArea}
      {SendButton}
    </div>
  );
}

export function Modal({ show, children }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">{children}</div>
    </div>
  );
}
