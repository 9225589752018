export async function shareGame() {
    if (navigator.share) {
        try {
            await navigator.share({
                title: "Share the joy of Lomax with the world!",
                text: "The second opinion you've been looking for this whole time: Lomax!",
                url: window.location.href,
            })
        } catch (error) {
            console.error('Error sharing:', error);
        }
    } else {
        // Fallback: Copy to clipboard
        copyToClipboard(window.location.href);
        alert('Link copied to clipboard!');
    }
};


async function copyToClipboard(text) {
    try {
        await navigator.clipboard.writeText(text);
        alert('Link copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy: ', err);
        alert('Failed to copy the link');
    }
};